import CheckSvg from "../CommonComponents/StatusIcons/CheckSvg";
import ClockSvg from "../CommonComponents/StatusIcons/ClockSvg";
import CrossSvg from "../CommonComponents/StatusIcons/CrossSvg";
import { isMobile } from "../helperFunc";

export const breadcrumbItems = [
  { text: "Storage", url: "/my-storage/commodities" },
  { text: "Commodities", url: "/my-storage/commodities" },
  { text: "Wheat", url: "" },
];
export const breadcrumbItemsWarehouse = [
  { text: "Storage", url: "/my-storage/commodities" },
  { text: "Warehouse", url: "/my-storage/warehouses" },
  { text: "Shibham", url: "" },
];

export const priceTrendCrumbItems = [
  { text: "Storage", url: "/my-storage/" },
  { text: "Commodities", url: "/my-storage/commodities" },
  { text: "Price Trend", url: "" },
];

export const PriceSideBarData = [
  {
    commodity: "Maize",
    rate: "₹23,500",
    location: "Noida, Uttar Pradesh",
    last_updated: "3 Days Ago",
  },
  {
    commodity: "Wheat",
    rate: "₹23,500",
    location: "Ghaziabad, Uttar Pradesh",
    last_updated: "4 Days Ago",
  },
  {
    commodity: "Maize",
    rate: "₹23,500",
    location: "Noida, Uttar Pradesh",
    last_updated: "2 Days Ago",
  },
];

export const PriceDescription = {
  commodity: "Wheat, Sharbati",
  rate: "₹23,500",
  location: "Jaipur, Rajasthan",
  last_updated: "12 April 2023",
  growth_rate: "+12.6%",
  money: "₹450",
  last_checked: "last week",
  forecasted_year: "2024",
  forecasted_month: "April",
  forecasted_price: "₹35,523",
};

export const CanData = [
  {
    title: "1 Week",
    lowest_price: "₹23,500 / MT",
    highest_price: "₹35,500 / MT",
    price_change: "₹500",
    id: 0,
  },
  {
    title: "1 Month",
    lowest_price: "₹23,500 / MT",
    highest_price: "₹35,500 / MT",
    price_change: "₹500",
    id: 1,
  },
  {
    title: "3 Months",
    lowest_price: "₹23,500 / MT",
    highest_price: "₹35,500 / MT",
    price_change: "₹500",
    id: 2,
  },
  {
    title: "6 Months",
    lowest_price: "₹23,500 / MT",
    highest_price: "₹35,500 / MT",
    price_change: "₹500",
    id: 3,
  },
  {
    title: "1 Year",
    lowest_price: "₹23,500 / MT",
    highest_price: "₹35,500 / MT",
    price_change: "₹500",
    id: 4,
  },
];

export const ListingStatus = {
  0: [
    <ClockSvg
      height={isMobile ? "20" : "24"}
      width={isMobile ? "20" : "24"}
      color="#AE750A"
    />,
  ],
  1: [
    <CheckSvg
      height={isMobile ? "20" : "24"}
      width={isMobile ? "20" : "24"}
      color="#179778"
    />,
  ],
  2: [
    <CrossSvg height={isMobile ? "20" : "24"} width={isMobile ? "20" : "24"} />,
  ],
  3: [
    <CrossSvg height={isMobile ? "20" : "24"} width={isMobile ? "20" : "24"} />,
  ],
  5: [
    <CrossSvg height={isMobile ? "20" : "24"} width={isMobile ? "20" : "24"} />,
  ],
};
export const ListingStatusName = {
  0: "Pending",
  1: "Accepted",
  2: "Rejected",
  3: "Cancelled",
  5: "Auto Rejected",
};
export const ListingStatusColor = {
  0: "alertWarning5",
  1: "colorAccent5",
  2: "textColorError",
  3: "textColorError",
  5: "textColorError",
};

export const PillBarTransfer = [
  { title: "Commodities", id: 0 },
  { title: "Transfer Request", id: 1 },
  { title: "Warehouse", id: 2 },
  { title: "Storage Invoices", id: 3 },
  { title: "Booking Request", id: 4 },
  { title: "Storage Contracts", id: 5 },
];
export const PillBarNormal = [
  { title: "Commodities", id: 0 },
  { title: "Warehouse", id: 2 },
  { title: "Storage Invoices", id: 3 },
  { title: "Storage Contracts", id: 5 },
];
